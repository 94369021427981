import React from 'react';
import { AppContextProvider } from 'contexts/AppContext';
import PageLayout from 'components/layout/PageLayout';
import { I18nProvider } from 'i18n';
import BlogList from '../layouts/BlogLayout/List/List';

const BlogIndex = () => {
  return (
    <AppContextProvider>
      <I18nProvider>
        <PageLayout
          // i18nTitle="blog.title"
          i18nMetaTitle="blog.meta_title"
          i18nMetaDescription="blog.meta_description"
        >
          <BlogList />
        </PageLayout>
      </I18nProvider>
    </AppContextProvider>
  );
};

export default BlogIndex;
