/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Img from 'gatsby-image';
import { Hidden, Visible } from 'components/Grid';
import { useI18n } from 'i18n';
import get from 'lodash/get';
import s from './List.module.scss';

const BlogList = ({ allMarkdownRemark: { edges: posts } }) => {
  const { locale } = useI18n();
  const postsToLocale = useMemo(
    () =>
      posts.filter(({ node: { fields: { slug } } }) => slug.includes(`/${locale}.index`)),
    [locale, posts],
  );
  return (
    <Grid className={s.container}>
      <Row className={s.row}>
        {postsToLocale.map(({ node }) => {
          const postTitle = node.frontmatter.title || node.fields.slug;
          return (
            <Col md={12} key={postTitle}>
              <Link to={node.frontmatter.path}>
                <Row className={s.post}>
                  <Col md={3}>
                    <Visible xs sm>
                      <span className={s.date}>
                        {node.frontmatter.date} · {node.fields.readingTime.text}
                      </span>
                    </Visible>
                    <div className={s.imgBox}>
                      <Img
                        fluid={get(node, 'frontmatter.featuredImage.childImageSharp.sizes')}
                        alt={node.frontmatter.featuredImageAltText}
                      />
                    </div>
                  </Col>
                  <Col md={9} className={s.info}>
                    <Hidden xs sm>
                      <span className={s.date}>
                        {node.frontmatter.date} · {node.fields.readingTime.text}
                      </span>
                    </Hidden>
                    <h2 className={s.title}>{postTitle}</h2>
                    <p className={s.description} dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                  </Col>
                </Row>
              </Link>
            </Col>
          );
        })}
      </Row>
    </Grid>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            author
            date(formatString: "MMMM DD, YYYY")
            title
            metaTitle
            metaDescription
            path
            featuredImageAltText
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 1400) {
                  ...GatsbyImageSharpSizes
                }
                resize(width: 800) {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DataQueryWrapper = () => <StaticQuery query={pageQuery} render={(data) => <BlogList {...data} />} />;

export default DataQueryWrapper;
